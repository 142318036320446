import { Select } from "baseui/select";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { useMemo } from "react";

export function DropdownPicker({ options, value, onChange }) {
  const baseuiOptions = useMemo(
    () => options?.map((option) => ({ id: option.name, label: option.desc })),
    [options],
  );
  const baseuiValue = useMemo(
    () => baseuiOptions?.find((option) => option.id === value),
    [baseuiOptions, value],
  );

  return (
    <Select
      searchable={false}
      options={baseuiOptions}
      value={baseuiValue ? [baseuiValue] : null}
      onChange={(params) => onChange(params.value[0]?.id || null)}
      clearable={!!value}
      overrides={{
        ControlContainer: {
          style: expandWithMerge({
            height: "37px",
            border: "1px solid rgba(18, 25, 57, .3)",
            backgroundColor: "white",
          }),
        },
        ValueContainer: { style: expandWithMerge({ padding: "0px 10px" }) },
        SingleValue: {
          style: {
            lineHeight: "35px",
            fontSize: "14px",
          },
        },
      }}
    />
  );
}

import { styled, useStyletron } from "baseui";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { Fragment, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { useData_message_latest } from "src/data/message";
import { useData_params_minWithdraw } from "src/data/params";
import { saveToken } from "src/utils/token";
import { Beian } from "src/widgets/Beian";
import { PopoverButton } from "src/widgets/PopoverButton";

import { invalidate_customer_overview, useData_customer_overview } from "../data/customer";
import format from "../utils/format";
import { boldTitleStyle, Text, View } from "../widgets/basic";
import { BankCardsTableModal } from "./BankCardsTableModal";
import { MessageDetailEntry } from "./MessageDetailEntry";
import { MessagesModal } from "./MessagesModal";
import { PasswordEditorModal } from "./PasswordEditorModal";
import { WithdrawRecordsTable } from "./WithdrawRecordsTable";

export function IndexPage() {
  const [css] = useStyletron();

  return (
    <View $style={{ minHeight: "100vh", backgroundColor: "#F4F8FE", alignItems: "center" }}>
      <Header />
      <Messages />
      <RepaymentProgress />
      <WithdrawRecordsTable />

      <View $style={{ flex: 1 }} />
      <View className={css({ margin: "40px 0 20px 0", textAlign: "center", color: "gray" })}>
        <Beian />
      </View>
    </View>
  );
}

function Header() {
  const { overview = {} } = useData_customer_overview();

  const [passwordEditorModal, setPasswordEditorModal] = useState(null);
  const [bankCardsTableModal, setBankCardsTableModal] = useState(null);

  const [css] = useStyletron();

  return (
    <View
      $style={{
        alignSelf: "stretch",
        height: "60px",
        backgroundColor: "white",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <View $style={{ width: "1280px", flexDirection: "row", alignItems: "center" }}>
        <img
          alt="logo"
          src={require("src/images/index_logo.png")}
          className={css({ width: "43px", height: "auto" })}
        />

        <Text $style={{ marginLeft: "10px", font: '500 18px/20px "PingFangSC-Medium"' }}>
          用户还款系统
        </Text>

        <div className={css({ flex: 1 })} />

        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          autoFocus={false}
          content={() => (
            <View>
              <PopoverButton onClick={() => setPasswordEditorModal({ isVisible: true })}>
                修改密码
              </PopoverButton>

              <PopoverButton
                onClick={() => {
                  saveToken(null);
                  window.location.replace("/Login");
                }}
              >
                退出登录
              </PopoverButton>
            </View>
          )}
          placement="bottomLeft"
          overrides={{ Body: { style: { marginTop: "-4px" } } }}
        >
          <View $style={{ alignSelf: "stretch", flexDirection: "row", alignItems: "center" }}>
            <img
              alt="username"
              src={require("src/images/index_username.png")}
              className={css({ width: "17px", height: "auto" })}
            />
            <HeaderFont $style={{ marginLeft: "11px" }}>
              {overview.name}（用户名 {overview.username}）
            </HeaderFont>
          </View>
        </StatefulPopover>

        <StatefulPopover
          triggerType={TRIGGER_TYPE.hover}
          autoFocus={false}
          content={() => (
            <View>
              <PopoverButton onClick={() => setBankCardsTableModal({ isVisible: true })}>
                管理银行账户
              </PopoverButton>
            </View>
          )}
          placement="bottomLeft"
          overrides={{ Body: { style: { marginTop: "-4px" } } }}
        >
          <View
            $style={{
              alignSelf: "stretch",
              marginLeft: "62px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              alt="bankCards"
              src={require("src/images/index_bankCards.png")}
              className={css({ width: "22px", height: "auto" })}
            />
            <HeaderFont $style={{ marginLeft: "6px" }}>
              我的银行账户 ({overview.bankCount})
            </HeaderFont>
          </View>
        </StatefulPopover>
      </View>

      <PasswordEditorModal
        {...passwordEditorModal}
        onClose={() => {
          setPasswordEditorModal(null);
          invalidate_customer_overview();
        }}
      />
      <BankCardsTableModal
        {...bankCardsTableModal}
        onClose={() => {
          setBankCardsTableModal(null);
          invalidate_customer_overview();
        }}
      />
    </View>
  );
}

const HeaderFont = styled("div", {
  lineHeight: "18px",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: "PingFangSC-Regular",
  color: "#538CFF",
});

function Messages() {
  const { message = {} } = useData_message_latest();

  const [modal, setModal] = useState(null);

  if (!message?.time) return null;

  return (
    <Fragment>
      <View
        $style={{
          marginTop: "16px",
          width: "1280px",
          height: "48px",
          background: "white",
          padding: "0 16px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text $style={{ color: "#FAAD3B" }}>
          {message.content
            ? `[ ${format.timeFull(message.time)} ] 【${message.title}】${message.content}`
            : ""}
        </Text>
        <MessageDetailEntry message={message} commaColor="#FAAD3B" />

        <View $style={{ flex: 1 }} />
        <Text
          onClick={() => setModal({ isOpen: true })}
          $style={{ cursor: "pointer", color: "#538CFF" }}
        >
          {"查看全部消息 >"}
        </Text>
      </View>

      <MessagesModal
        {...modal}
        onClose={() => {
          setModal(null);
          invalidate_customer_overview();
        }}
      />
    </Fragment>
  );
}

function RepaymentProgress() {
  const { overview = {} } = useData_customer_overview();

  return (
    <View $style={{ marginTop: "14px", width: "1280px", background: "white", padding: "0 16px" }}>
      <View $style={{ marginTop: "36px", ...boldTitleStyle, color: "#121939" }}>还款进度</View>

      <View
        $style={{
          marginTop: "12px",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text>协议总额 = 剩余待还 + 已提现金额 + 待提现金额</Text>
        <Text>最近更新时间：{format.time(overview.lastUpdateTime)}</Text>
      </View>

      <View $style={{ margin: "34px 0 30px 0", flexDirection: "row", alignItems: "center" }}>
        <ProgressCircle />
        <ProgressDetails />
      </View>
    </View>
  );
}

function ProgressCircle() {
  const { overview = {} } = useData_customer_overview();

  if (!overview.username) return null;

  return (
    <View
      $style={{
        width: "208px",
        height: "208px",
        borderRadius: "50%",
        background: "rgba(253, 177, 120, 0.07)",
        padding: "12px",
      }}
    >
      <PieChart
        data={[
          { value: overview.withdrawFee, color: "#41B67E" },
          { value: overview.toWithdrawFee, color: "#F1C356" },
          {
            value:
              !overview.withdrawFee && !overview.toWithdrawFee && !overview.toRepayLeftFee
                ? 1
                : overview.toRepayLeftFee,
            color: "#FF5B53",
          },
        ]}
        startAngle={-90}
        lineWidth={42}
      />

      <View
        $style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>还款进度</Text>
      </View>
    </View>
  );
}

function ProgressDetails() {
  const { overview = {} } = useData_customer_overview();
  const { minWithdraw } = useData_params_minWithdraw();

  const [css] = useStyletron();

  return (
    <View $style={{ flex: 1, flexDirection: "row", justifyContent: "space-evenly" }}>
      <ProgressDetail
        label="协议总额"
        labelColor="#FF5B53"
        fee={overview.toRepayTotalFee}
        feeCN={overview.toRepayTotalFeeCN}
        numberStyle={{ fontSize: "24px", fontWeight: "500", fontFamily: "Arial-BoldMT" }}
      />

      <View $style={{ height: "24px", justifyContent: "center" }}>
        <Text>=</Text>
      </View>

      <ProgressDetail
        label="剩余待还"
        labelColor="#FF5B53"
        fee={overview.toRepayLeftFee}
        feeCN={overview.toRepayLeftFeeCN}
        numberStyle={{
          lineHeight: "33px",
          fontSize: "30px",
          fontWeight: "500",
          fontFamily: "DINAlternate-Bold",
        }}
      />

      <View $style={{ height: "24px", justifyContent: "center" }}>
        <Text>+</Text>
      </View>

      <ProgressDetail
        label="已提现金额"
        labelColor="#41B67E"
        fee={overview.withdrawFee}
        feeCN={overview.withdrawFeeCN}
        numberStyle={{
          lineHeight: "33px",
          fontSize: "30px",
          fontWeight: "500",
          fontFamily: "DINAlternate-Bold",
        }}
      />

      <View $style={{ height: "24px", justifyContent: "center" }}>
        <Text>+</Text>
      </View>

      <View>
        <ProgressDetail
          label="待提现金额"
          labelColor="#F1C356"
          fee={overview.toWithdrawFee}
          feeCN={overview.toWithdrawFeeCN}
          numberStyle={{
            lineHeight: "33px",
            fontSize: "30px",
            fontWeight: "500",
            fontFamily: "DINAlternate-Bold",
          }}
        />

        {minWithdraw > 0 && (
          <View $style={{ position: "absolute", top: "-33px", left: "-14px" }}>
            <img
              alt={`满 ${minWithdraw} 元可提现`}
              src={require("src/images/toWithdraw_hint.png")}
              className={css({ width: "118px", height: "auto" })}
            />
            <Text
              $style={{
                position: "absolute",
                top: "6px",
                left: 0,
                right: 0,
                textAlign: "center",
                fontSize: "12px",
                color: "#538CFF",
              }}
            >
              满{minWithdraw}元可提现
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

function ProgressDetail({ label, labelColor, fee, feeCN, numberStyle }) {
  return (
    <View $style={{ width: "182px" }}>
      <View
        $style={{
          width: "90px",
          height: "24px",
          background: labelColor,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text $style={{ color: "white" }}>{label}</Text>
      </View>

      <Text
        $style={{
          marginTop: "18px",
          lineHeight: "27px",
          fontSize: "24px",
          ...numberStyle,
        }}
      >
        ¥ {format.fee(fee)}
      </Text>

      <Text $style={{ marginTop: "14px" }}>{feeCN}</Text>
    </View>
  );
}

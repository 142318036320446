import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { ToasterContainer } from "baseui/toast";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { cssTransition, ToastContainer } from "react-toastify";

import { useData_customer_overview } from "./data/customer";
import { IndexPage } from "./pages/IndexPage";
import { LoginPage } from "./pages/LoginPage";
import { getToken } from "./utils/token";

function App() {
  const token = getToken();
  const { error } = useData_customer_overview();

  useEffect(() => {
    if (window.location.pathname !== "/Login" && (!token || error)) window.location.href = "/Login";
  }, [error, token]);

  return (
    <div className="App">
      {/* 因为无法用一个控件来实现正常和错误提示显示在不同的位置，所以只好引入两个控件来分别实现了 */}
      {/** 一般提示 NOTICE 升级警告： 7.x- 不会自动消失！！ */}
      <ToastContainer
        autoClose={4000}
        position="bottom-center"
        closeButton={false}
        transition={cssTransition({
          enter: "slideInDown",
          exit: "slideOutUp",
          duration: 300,
        })}
        hideProgressBar={true}
        toastClassName="toast-toast"
        bodyClassName="toast-body"
      />
      {/** 错误提示 */}
      <ToasterContainer
        autoHideDuration={10000}
        placement="top"
        overrides={{
          ToastBody: {
            style: expandWithMerge({
              borderRadius: "4px",
            }),
          },
        }}
      />

      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/Login" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;

import { Input } from "baseui/input";
import { expandWithMerge } from "inline-style-expand-shorthand";

export function PasswordInput(props) {
  return (
    <Input
      {...props}
      type="password"
      value={props.value || ""}
      overrides={{
        Root: {
          style: expandWithMerge({
            height: "66px",
            borderColor: "#f4f8fe",
            backgroundColor: "#f4f8fe",
            ...props.style,
          }),
        },
        Input: {
          style: {
            backgroundColor: "#f4f8fe",
            "::placeholder": {
              color: "rgba(18, 25, 57, .45)",
            },
          },
        },
        StartEnhancer: { style: { backgroundColor: "#f4f8fe" } },
        MaskToggleButton: () => null,
      }}
    />
  );
}

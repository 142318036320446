import { Button } from "baseui/button";
import { expandWithMerge } from "inline-style-expand-shorthand";

export function CancelButton({ style, children, ...props }) {
  return (
    <Button
      {...props}
      overrides={{
        BaseButton: {
          style: ({ $disabled }) =>
            expandWithMerge({
              width: "90px",
              height: "32px",
              backgroundColor: "#F4F6FC",
              ":hover": { backgroundColor: "#F4F6FCCC" },
              ...style,
            }),
        },
      }}
    >
      {children}
    </Button>
  );
}

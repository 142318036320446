import { ConfigProvider, Pagination } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import { useStyletron } from "baseui";
import { Table } from "baseui/table-semantic";
import dayjs from "dayjs";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { cloneDeep } from "lodash";
import { action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { Fragment, useState } from "react";
import { invalidate_customer_overview } from "src/data/customer";
import { useData_params_minWithdraw } from "src/data/params";
import { useData_withdraw_applyMaxFee } from "src/data/withdraw";
import format from "src/utils/format";
import logic from "src/utils/logic";
import { useQueryWrapper } from "src/utils/queryHelpers";
import { theQueryClient } from "src/utils/theQueryClient";
import ui from "src/utils/ui";
import { BlueButton } from "src/widgets/BlueButton";
import { WhiteDatePicker } from "src/widgets/WhiteDatePicker";
import { WhiteInput } from "src/widgets/WhiteInput";

import { boldTitleStyle, Text, View } from "../widgets/basic";
import { DropdownPicker } from "../widgets/DropdownPicker";
import { WithdrawApplyModal } from "./WithdrawApplyModal";
import { WithdrawDetailModal } from "./WithdrawDetailModal";

const URL_WITHDRAW_PAGE = "/front/withdraw/page";

const DEFAULT_SEARCHES = {
  status: null,
  fee: null,
  account: null,
  applyTimeStart: null,
  applyTimeEnd: null,
  handleTimeStart: null,
  handleTimeEnd: null,
};

class LocalStore {
  inputs = cloneDeep(DEFAULT_SEARCHES);
  searches = cloneDeep(DEFAULT_SEARCHES);

  records = [];
  pageIndex = 1;
  pageSize = 20;
  totalRecords = 0;

  withdrawApplyModal = null;
  withdrawDetailModal = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  invalidateTableData() {
    invalidate_customer_overview();
    theQueryClient.invalidateQueries(URL_WITHDRAW_PAGE);
  }
}

export const WithdrawRecordsTable = observer(function WithdrawRecordsTable() {
  const [localStore] = useState(() => new LocalStore());

  const { maxApplicableFee } = useData_withdraw_applyMaxFee();
  const { minWithdraw } = useData_params_minWithdraw();

  const [css] = useStyletron();

  return (
    <View
      $style={expandWithMerge({
        marginTop: "14px",
        width: "1280px",
        background: "white",
        padding: "16px 16px 20px 16px",
      })}
    >
      <View
        $style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
      >
        <Text $style={boldTitleStyle}>提现记录</Text>

        <BlueButton
          onClick={action(() => {
            if (maxApplicableFee >= minWithdraw) {
              localStore.withdrawApplyModal = { isVisible: true };
            } else {
              ui.toastError(`当前可申请提现的金额不足${minWithdraw}元，暂不支持提现`);
            }
          })}
          style={{ width: "88px", height: "32px", padding: 0 }}
        >
          申请提现
        </BlueButton>
      </View>

      <div
        className={css({
          display: "grid",
          marginTop: "24px",
          width: "100%",
          gridTemplateColumns: "repeat(3, auto 1fr)",
          rowGap: "15px",
          columnGap: "11px",
          alignItems: "center",
        })}
      >
        <Text $style={{ textAlign: "right" }}>申请状态</Text>
        <DropdownPicker
          options={[
            { name: null, desc: "(全部)" },
            ...Object.keys(logic.WITHDRAW_STATUS).map((option) => ({ name: option, desc: option })),
          ]}
          value={localStore.inputs.status}
          onChange={action((input) => (localStore.inputs.status = input))}
        />

        <Text $style={{ marginLeft: "33px", textAlign: "right" }}>申请金额</Text>
        <WhiteInput
          type="number"
          placeholder="请输入申请收款的金额数字"
          value={localStore.inputs.fee}
          onChange={action((e) => (localStore.inputs.fee = e.target.value))}
        />

        <Text $style={{ marginLeft: "33px", textAlign: "right" }}>银行卡号</Text>
        <WhiteInput
          type="number"
          placeholder="请输入申请收款的银行卡号"
          value={localStore.inputs.account || ""}
          onChange={action((e) => (localStore.inputs.account = e.target.value))}
        />

        <Text $style={{ textAlign: "right" }}>申请时间</Text>
        <WhiteDatePicker
          range
          value={[localStore.inputs.applyTimeStart, localStore.inputs.applyTimeEnd]}
          onChange={action(({ date }) => {
            localStore.inputs.applyTimeStart = date[0];
            localStore.inputs.applyTimeEnd = date[1] ? dayjs(date[1]).endOf("day").toDate() : null;
          })}
        />

        <Text $style={{ textAlign: "right" }}>处理时间</Text>
        <WhiteDatePicker
          range
          value={[localStore.inputs.handleTimeStart, localStore.inputs.handleTimeEnd]}
          onChange={action(({ date }) => {
            localStore.inputs.handleTimeStart = date[0];
            localStore.inputs.handleTimeEnd = date[1] ? dayjs(date[1]).endOf("day").toDate() : null;
          })}
        />

        <View $style={{ gridColumn: "5 / span 2", flexDirection: "row", alignItems: "center" }}>
          <View $style={{ flex: 1 }} />

          <BlueButton
            onClick={action(() => {
              localStore.inputs = cloneDeep(DEFAULT_SEARCHES);
            })}
            type="primary"
            style={{ width: "112px", height: "32px" }}
          >
            清空
          </BlueButton>

          <BlueButton
            onClick={action(() => {
              localStore.searches = cloneDeep(localStore.inputs);
              localStore.pageIndex = 1;
              localStore.invalidateTableData();
            })}
            type="primary"
            style={{ marginLeft: "16px", width: "258px", height: "32px" }}
          >
            搜索
          </BlueButton>
        </View>
      </div>

      <TableData localStore={localStore} />

      <WithdrawApplyModal
        {...localStore.withdrawApplyModal}
        onClose={action(() => {
          localStore.withdrawApplyModal = null;
          localStore.invalidateTableData();
        })}
      />

      <WithdrawDetailModal
        {...localStore.withdrawDetailModal}
        onClose={action(() => {
          localStore.withdrawDetailModal = null;
          localStore.invalidateTableData();
        })}
      />
    </View>
  );
});

const TableData = observer(function TableData({ localStore }) {
  const { data, error, isLoading } = useQueryWrapper(
    URL_WITHDRAW_PAGE,
    {
      ...localStore.searches,
      applyTimeStart: format.date(localStore.searches.applyTimeStart),
      applyTimeEnd: format.date(localStore.searches.applyTimeEnd),
      handleTimeStart: format.date(localStore.searches.handleTimeStart),
      handleTimeEnd: format.date(localStore.searches.handleTimeEnd),
      pageIndex: localStore.pageIndex,
      pageSize: localStore.pageSize,
    },
    true,
    { jsonRequest: true },
    { keepPreviousData: true },
  );
  ui.useToast(error);

  return (
    <Fragment>
      <Table
        columns={["编号", "申请状态", "申请金额", "打款银行账户", "申请时间", "处理时间"]}
        data={data?.page?.beanList?.map((record) => [
          record.withdrawRecordId,
          <Text $style={{ color: logic.WITHDRAW_STATUS[record.status] }}>{record.status}</Text>,
          `￥${format.fee(record.fee)}`,
          `【${record.name}】【${record.bankOfDeposit}】${record.account}`,
          format.time(record.applyTime),
          format.time(record.handleTime),
        ])}
        overrides={{
          Root: {
            style: {
              marginTop: "17px",
            },
          },
          TableHeadCell: {
            style: expandWithMerge({
              height: "32px",
              border: "none",
              backgroundColor: "#F4F6FC",
              padding: "0px",
              lineHeight: "32px",
              textAlign: "center",
              fontSize: "14px",
            }),
          },
          TableBodyRow: {
            props: {
              onClick: action((e) => {
                localStore.withdrawDetailModal = {
                  isVisible: true,
                  withdraw: data?.page?.beanList?.[e.target.closest("tr").rowIndex - 1],
                };
              }),
            },
          },
          TableBodyCell: {
            style: expandWithMerge({
              border: "none",
              textAlign: "center",
            }),
          },
        }}
        isLoading={isLoading}
      />

      <ConfigProvider locale={zh_CN}>
        <Pagination
          pageSize={localStore.pageSize}
          total={data?.page?.total}
          current={localStore.pageIndex}
          onChange={action((pageIndex, pageSize) => {
            localStore.pageIndex = pageIndex;
            localStore.pageSize = pageSize;
            localStore.invalidateTableData();
          })}
          pageSizeOptions={[20, 50, 100]}
          showSizeChanger
          showTotal={(total, range) => `${range[0]}-${range[1]} 共${total}条`}
          style={{ alignSelf: "flex-end", marginTop: "20px" }}
        />
      </ConfigProvider>
    </Fragment>
  );
});

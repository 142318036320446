import { queryKey, useQueryWrapper } from "src/utils/queryHelpers";
import ui from "src/utils/ui";

export function key_withdraw_page() {
  return queryKey("/front/withdraw/page");
}

export function useData_withdraw_detail(withdrawId) {
  const { data, error, ...rest } = useQueryWrapper(
    "/front/withdraw/detail",
    { withDrawRecordId: withdrawId },
    true,
    null,
    { enabled: !!withdrawId },
  );
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    withdraw: data?.withdrawRecord,
  };
}

export function key_withdraw_applyMaxFee() {
  return queryKey("/front/withdraw/apply-max-fee", {}, true);
}

export function useData_withdraw_applyMaxFee() {
  const { data, error, ...rest } = useQueryWrapper(key_withdraw_applyMaxFee());
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    maxApplicableFee: data?.fee,
  };
}

import dayjs from "dayjs";
import diff from "deep-diff";
import { toJS } from "mobx";
import numeral from "numeral";
import { v4 as uuidv4 } from "uuid";

const format = {};

format.uuid = () => uuidv4();

format.diff = (lhs, rhs) =>
  diff(lhs, rhs, {
    normalize: (_, __, l, r) => {
      if (l == null) {
        l = null;
      }
      if (r == null) {
        r = null;
      }
      return [l, r];
    },
  });

format.multiline = (text) =>
  (text || "")
    .split("\n")
    .map((d, i) => ({ i, d: d.trim() }))
    .filter((d) => d.d)
    .map((d) => <div key={d.i}>{d.d}</div>);

format.fixed = (num, placeholder, count = 2) =>
  Number.isFinite(num)
    ? numeral(num).format(`0${count > 0 ? `.[${"0".repeat(count)}]` : ""}`)
    : placeholder;

format.fee = (fee) => (Number.isFinite(fee) ? numeral(fee).format("0,0.[00]") : "");

format.date = (time) => (time ? dayjs(time).format("YYYY-MM-DD") : null);
format.time = (time) => (time ? dayjs(time).format("YYYY-MM-DD HH:mm") : null);
format.timeFull = (time) => (time ? dayjs(time).format("YYYY-MM-DD HH:mm:ss") : null);
format.showTime = (time) => (time ? dayjs(time).format("MM-DD HH:mm") : null);

format.countdown = (time, now) => {
  time = dayjs(time);
  now = now || dayjs();
  const duration = dayjs.duration(time.diff(now));
  const days = time.isAfter(now) ? time.diff(now, "days") : 0;
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  return `${days}天${hours}时${minutes}分${seconds}秒`;
};

/** 清理下传给服务端的参数对象，下划线开头的字段一般都是前端加载，方便界面实现 */
format.stripObject = (object) => {
  object = toJS(object);
  if (!object) return object;
  if (object instanceof Date) return object;
  if (Array.isArray(object)) return object.map((o) => format.stripObject(o));
  if (typeof object !== "object") return object;
  return Object.assign(
    {},
    ...Object.keys(object || {})
      .filter((field) => !field.startsWith("_"))
      .map((field) => ({ [field]: format.stripObject(object[field]) })),
  );
};

format.cell = (cell) => (!cell ? null : `${cell.substr(0, 3)}****${cell.substr(7, 4)}`);

format.vehicle = (vehicle) =>
  vehicle && vehicle.id ? `${vehicle.name}【${vehicle.color}】${vehicle.plateNumber || "--"}` : "";

format.plateNumber = (pn) => {
  if (!pn) {
    return "--";
  }
  if (pn.length < 4) {
    return pn;
  }
  return pn.substr(0, 2) + "*".repeat(pn.length - 3) + pn.slice(-1);
};

format.payMode = (fundPurpose) => {
  const store = window.g_app._store;
  return (
    store.getState().global.enums.payMode.map[fundPurpose.payMode] ||
    store.getState().global.enums.payMode.map[fundPurpose.parentPayMode]
  );
};

format.feeType = (fundPurpose) => {
  const store = window.g_app._store;
  const f = fundPurpose.feeType;
  return f !== "OTHER"
    ? store.getState().global.feeTypes.map[f]?.description
    : `其他-${fundPurpose.feeTypeOtherName}`;
};

format.regainConclusion = (regain) => {
  switch (regain.conclusion) {
    case "PLAN":
      return `${format.date(regain.nextCallTime)} 再回访`;
    case "SYSTEM":
      return "按照系统规则下次再回访";
    case "NO_MORE":
      return "不再回访";
    case "NEED_USE_VEHICLE_TODAY":
      return `明确有需要用车-今日（${format.date(regain.nextCallTime)}）稍后下单`;
    case "NEED_USE_VEHICLE_AFTER":
      return `明确有需要用车-${format.date(regain.nextCallTime)} 再回访下单`;
    default:
      return null;
  }
};

// 时间转为毫秒 11:44
format.timeToSec = (time, type = 1) => {
  //  暂时用不到先放这吧
  if (!time) {
    return null;
  }
  //  type  1 时分秒    2 时分
  let hour = 0;
  let min = 0;
  let sec = 0;

  if (type === 1) {
    hour = time.split(":")[0];
    min = time.split(":")[1];
    sec = time.split(":")[2];
  } else {
    hour = time.split(":")[0];
    min = time.split(":")[1];
  }
  const s = Number(hour * 3600) + Number(min * 60) + Number(sec);
  return dayjs(s * 1000, "HH:mm:ss");
};

export default format;

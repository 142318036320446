import { queryKey, useQueryWrapper } from "src/utils/queryHelpers";
import ui from "src/utils/ui";

export function key_params_minWithdraw() {
  return queryKey("/front/params/min-withdraw");
}

export function useData_params_minWithdraw() {
  const { data, error, ...rest } = useQueryWrapper(key_params_minWithdraw());
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    minWithdraw: data?.minWithdraw,
  };
}

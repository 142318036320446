import { Modal } from "baseui/modal";
import { Table } from "baseui/table-semantic";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { observer } from "mobx-react";
import { Fragment, useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import { useData_withdraw_detail } from "src/data/withdraw";
import format from "src/utils/format";
import logic from "src/utils/logic";
import { boldTitleStyle, Text, View } from "src/widgets/basic";

export const WithdrawDetailModal = observer(function WithdrawDetailModal({
  isVisible,
  onClose,
  withdrawId,
  withdraw = {},
}) {
  const [previewedImage, setPreviewedImage] = useState(null);

  const { withdraw: newWithdraw = {} } = useData_withdraw_detail(isVisible && withdrawId);
  withdraw = withdraw.status ? withdraw : newWithdraw;

  return (
    <Fragment>
      <Modal
        isOpen={isVisible}
        onClose={onClose}
        overrides={{
          Dialog: {
            style: expandWithMerge({
              width: "862px",
              borderRadius: 0,
            }),
          },
        }}
      >
        <View $style={expandWithMerge({ width: "862px", padding: "35px 25px 60px 56px" })}>
          <Text $style={{ marginLeft: "-38px", ...boldTitleStyle }}>查看提现申请</Text>

          <View $style={{ marginTop: "34px", flexDirection: "row", alignItems: "center" }}>
            <Text>
              申请金额：
              <span style={{ color: "#FD4C4C" }}>￥{format.fee(withdraw.fee)}</span>
            </Text>

            <View $style={{ flex: 1 }} />
            <Text>编号 {withdraw.withdrawRecordId}</Text>
            <View
              $style={{
                marginLeft: "16px",
                minWidth: "104px",
                height: "24px",
                backgroundColor: logic.WITHDRAW_STATUS[withdraw.status],
                padding: "0 4px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text $style={{ color: "#fff" }}>{withdraw.status}</Text>
            </View>
          </View>

          <Text $style={{ marginTop: "20px" }}>申请时间：{format.time(withdraw.applyTime)}</Text>

          <Text $style={{ marginTop: "20px" }}>打款银行账户：</Text>
          <Table
            columns={["名称", "开户银行", "银行账号", "开户行说明"]}
            data={[
              [
                withdraw.name,
                withdraw.bankOfDeposit,
                withdraw.account,
                withdraw.bankOfDepositRemark || "--",
              ],
            ]}
            overrides={{
              Root: {
                style: {
                  marginTop: "7px",
                  border: "1px solid #F4F6FC",
                },
              },
              TableHeadCell: {
                style: {
                  height: "32px",
                  border: "none",
                  backgroundColor: "#F4F6FC",
                  padding: "0px",
                  lineHeight: "32px",
                  textAlign: "center",
                  fontSize: "14px",
                },
              },
              TableBodyCell: {
                style: {
                  border: "none",
                  textAlign: "center",
                },
              },
            }}
          />

          {withdraw.status !== "打款申请中" && (
            <Fragment>
              <Text $style={{ marginTop: "20px" }}>
                打款状态：
                <span style={{ color: logic.WITHDRAW_STATUS[withdraw.status] }}>
                  {withdraw.status}
                </span>
              </Text>

              {!!withdraw.image && (
                <View $style={{ marginTop: "20px", flexDirection: "row" }}>
                  <Text>处理截图：</Text>
                  {withdraw.image.split(",").map((img) => (
                    <img
                      key={img}
                      alt="处理截图"
                      src={img}
                      onClick={() => setPreviewedImage(img)}
                      style={{ marginRight: 8, maxWidth: 88, maxHeight: 88 }}
                    />
                  ))}
                </View>
              )}

              {!!withdraw.handleRemark && (
                <Text $style={{ marginTop: "20px" }}>处理说明：{withdraw.handleRemark}</Text>
              )}

              <Text $style={{ marginTop: "20px" }}>
                处理时间：{format.time(withdraw.handleTime)}
              </Text>
            </Fragment>
          )}
        </View>
      </Modal>

      {!!previewedImage && (
        <ImageViewer
          src={[previewedImage]}
          onClose={() => setPreviewedImage(null)}
          closeOnClickOutside
          disableScroll={false}
        />
      )}
    </Fragment>
  );
});

import { QueryClient } from "react-query";

export const theQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      // refetchOnWindowFocus: false,
      // NOTICE 先不要自动重试，避免给服务端太大的压力
      // TODO 但好像仍然会重试一次
      retry: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    },
  },
});

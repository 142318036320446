import { theQueryClient } from "src/utils/theQueryClient";

import { queryKey, useQueryWrapper } from "../utils/queryHelpers";
import ui from "../utils/ui";
import { key_message_latest } from "./message";
import { key_params_minWithdraw } from "./params";
import { key_withdraw_applyMaxFee, key_withdraw_page } from "./withdraw";

export function key_customer_overview() {
  return queryKey("/front/customer/overview", {}, true);
}

export function useData_customer_overview() {
  const { data, error, ...rest } = useQueryWrapper(key_customer_overview());
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    overview: data?.overview,
  };
}

export function invalidate_customer_overview() {
  theQueryClient.resetQueries(key_customer_overview());
  theQueryClient.resetQueries(key_message_latest());
  theQueryClient.resetQueries(key_withdraw_applyMaxFee());
  theQueryClient.resetQueries(key_withdraw_page());
  theQueryClient.resetQueries(key_params_minWithdraw());
}

import { Fragment, useState } from "react";
import { invalidate_customer_overview } from "src/data/customer";
import { Text } from "src/widgets/basic";

import { WithdrawDetailModal } from "./WithdrawDetailModal";

export function MessageDetailEntry({ message, commaColor }) {
  const [isVisible, setIsVisible] = useState(false);

  if (!message?.withdrawRecordId) return null;

  return (
    <Fragment>
      <Text $style={{ color: commaColor }}>
        ，
        <Text onClick={() => setIsVisible(true)} $style={{ cursor: "pointer", color: "#538CFF" }}>
          {"查看详情 >"}
        </Text>
      </Text>

      <WithdrawDetailModal
        isVisible={isVisible}
        onClose={() => {
          setIsVisible(false);
          invalidate_customer_overview();
        }}
        withdrawId={message.withdrawRecordId}
      />
    </Fragment>
  );
}

import { toaster } from "baseui/toast";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { usePrevious } from "./usePrevious";

const ui = {};

ui.OSS_STATIC = "https://wagonsclub.oss-cn-beijing.aliyuncs.com/static";

ui.toastInfo = (message) => {
  toast(message);
};

ui.toastError = (message) => {
  message = message || "未知错误";
  if (message instanceof Error) {
    message = message.message;
  }

  toaster.negative(message);
};

ui.useToast = function useToast(error) {
  const prevError = usePrevious(error);
  useEffect(() => {
    if (error && error !== prevError) {
      ui.toastError(error);
    }
  }, [error, prevError]);
};

export default ui;

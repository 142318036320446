import { Button } from "baseui/button";
import { expandWithMerge } from "inline-style-expand-shorthand";

export function BlueButton({ style, children, ...props }) {
  return (
    <Button
      {...props}
      overrides={{
        BaseButton: {
          style: ({ $disabled }) =>
            expandWithMerge({
              backgroundColor: "#538CFF",
              fontSize: "14px",
              ":hover": { backgroundColor: $disabled ? "#f8f8f8" : "#a0bfff" },
              ...style,
            }),
        },
      }}
    >
      {children}
    </Button>
  );
}

import { useStyletron } from "baseui";
import ui from "src/utils/ui";

import { View } from "./basic";

const GONGAN_BEIAN_NO = "11010502050541";

export function Beian() {
  const [css] = useStyletron();

  return (
    <View $style={{ flexDirection: "row", alignItems: "center" }}>
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
        rel="noopener noreferrer"
        className={css({ color: "gray" })}
      >
        京ICP备17029862号-2
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${GONGAN_BEIAN_NO}`}
        className={css({
          display: "flex",
          position: "relative",
          marginLeft: "32px",
          flexDirection: "row",
          alignItems: "center",
        })}
      >
        <img
          alt=""
          src={ui.OSS_STATIC + "/frontweb/icp.png"}
          className={css({ display: "inline-block" })}
        />
        <span className={css({ marginLeft: "4px", color: "gray" })}>
          京公网安备 {GONGAN_BEIAN_NO}号
        </span>
      </a>
    </View>
  );
}

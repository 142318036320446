import { ConfigProvider, Pagination } from "antd";
import zh_CN from "antd/lib/locale/zh_CN";
import { Modal } from "baseui/modal";
import { useState } from "react";
import format from "src/utils/format";
import { useQueryWrapper } from "src/utils/queryHelpers";
import ui from "src/utils/ui";
import { boldTitleStyle, Text, View } from "src/widgets/basic";

import { MessageDetailEntry } from "./MessageDetailEntry";

export function MessagesModal({ isOpen, onClose }) {
  const [pageIndex, setPageIndex] = useState(1);

  const { data, error } = useQueryWrapper(
    "/front/message/page",
    { pageIndex, pageSize: 10 },
    true,
    { jsonRequest: true },
    { enabled: !!isOpen, keepPreviousData: true },
  );
  ui.useToast(error);

  const pageData = data?.page || {};

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: {
            width: "862px",
            borderRadius: 0,
          },
        },
      }}
    >
      <View $style={{ padding: "20px 16px" }}>
        <Text $style={boldTitleStyle}>我的消息</Text>
        <View
          $style={{
            margin: "22px 0",
            width: "100%",
            height: "1px",
            background: "rgba(18, 25, 57, 0.05)",
          }}
        />

        {pageData.beanList?.map((message) => (
          <View
            key={message.time}
            $style={{
              margin: "0 16px",
              height: "66px",
              borderBottom: "1px solid rgba(18, 25, 57, 0.05)",
              justifyContent: "center",
            }}
          >
            <View
              $style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text $style={{ font: "500 16px/18px PingFangSC-Medium" }}>{message.title}</Text>
              <Text>{format.timeFull(message.time)}</Text>
            </View>

            <Text $style={{ marginTop: "8px", color: "rgba(18, 25, 57, 0.45)" }}>
              {message.content}
              <MessageDetailEntry message={message} commaColor="rgba(18, 25, 57, 0.45)" />
            </Text>
          </View>
        ))}

        <ConfigProvider locale={zh_CN}>
          <Pagination
            total={pageData?.total}
            current={pageIndex}
            onChange={(pageIndex) => {
              setPageIndex(pageIndex);
            }}
            showTotal={(total, range) => `${range[0]}-${range[1]} 共${total}条`}
            style={{ alignSelf: "flex-end", marginTop: "20px" }}
          />
        </ConfigProvider>
      </View>
    </Modal>
  );
}

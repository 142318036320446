import { queryKey, useQueryWrapper } from "../utils/queryHelpers";
import ui from "../utils/ui";

export function key_message_latest() {
  return queryKey("/front/message/latest", {}, true);
}

export function useData_message_latest() {
  const { data, error, ...rest } = useQueryWrapper(key_message_latest());
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    message: data?.message,
  };
}

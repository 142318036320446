import { Input } from "baseui/input";
import { expandWithMerge } from "inline-style-expand-shorthand";

export const whiteInputOverrides = {
  Root: {
    style: expandWithMerge({
      height: "37px",
      border: "1px solid rgba(18, 25, 57, .3)",
      backgroundColor: "white",
    }),
  },
  Input: {
    style: {
      backgroundColor: "white",
      fontSize: "14px",
      fontFamily: "PingFangSC-Regular",
      "::placeholder": {
        color: "rgba(18, 25, 57, .45)",
      },
    },
  },
  EndEnhancer: {
    style: {
      pointerEvents: "none",
      backgroundColor: "white",
    },
  },
};

export function WhiteInput(props) {
  return <Input {...props} value={props.value || ""} overrides={whiteInputOverrides} />;
}

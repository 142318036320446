import axios from "axios";
import qs from "qs";

import format from "./format";
import { getToken, saveToken } from "./token";

const api = axios.create({
  baseURL: "/apiproxy/repayment",
  timeout: 60000,
  headers: {
    Accept: "application/json; charset=UTF-8",
  },
});

api.interceptors.request.use((request) => {
  request.params = {
    ...request.params,
    token: getToken(),
    screen: window.location.pathname,
    clientType: "repayment-backweb",
  };

  return request;
});

api.interceptors.response.use(
  (response) => {
    if (/\/oauth\//.test(response.config.url)) {
      if (response.data.error) {
        console.warn("oauth2 error:", response);
        return Promise.reject(new Error(`${response.data.error_description}`));
      }
    } else if (!response.data.success) {
      console.warn("server error:", response);
      if (response.data.message === "未登录") {
        saveToken(null);
        window.location.href = "/Login";
      }
      return Promise.reject(new Error(response.data.message));
    }
    return response.data;
  },
  (error) => {
    console.warn("api error:", error, error.response);

    const msg =
      {
        413: "文件太大",
      }[error.response?.status] ||
      // oauth 的错误格式
      error.response?.data?.error_description ||
      // 服务端错误格式
      error.response?.data?.message ||
      // Error 对象错误格式
      error.message ||
      error.toString();

    return Promise.reject(new Error(msg));
  },
);

// 要调试网络的话，就加个感叹号，但别提交！
if (process.env.NON_EXISTANT_VARIABLE) {
  api.interceptors.request.use((request) => {
    console.log("axios request:", request);
    return request;
  });
  api.interceptors.response.use((response) => {
    console.log("axios response:", response);
    return response;
  });
}

const api2 = {};

api2.request = async (method, url, params, options) => {
  const beginTime = new Date().valueOf();
  try {
    const strippedParams = format.stripObject(params);

    let result;
    switch (method) {
      case "get":
      case "GET":
        result = await api.request({
          method,
          url,
          params: options?.jsonRequest
            ? { paramsStr: JSON.stringify(strippedParams) }
            : strippedParams,
        });
        break;

      case "post":
      case "POST":
        result = await api.request({
          method,
          url,
          data:
            params instanceof FormData
              ? params
              : options?.jsonRequest
              ? qs.stringify({ paramsStr: JSON.stringify(strippedParams) })
              : qs.stringify(strippedParams),
        });
        break;

      default:
    }

    console.log(method?.toUpperCase(), url, new Date() - beginTime, strippedParams, result);

    if (
      // oauth 相关的接口，需要遵循 oauth2 规范，所以没有我们的 success 之类的结果字段。
      !/\/oauth\//.test(url) &&
      !result.success
    ) {
      throw new Error(result.message);
    }

    return result;
  } catch (error) {
    console.warn(method, url, new Date().valueOf() - beginTime, params, error.response, error);
    throw error;
  }
};

api2.get = (url, params, options) => api2.request("GET", url, params, options);

api2.getByJson = (url, params, options) => {
  return api2.request("GET", url, params, { ...options, jsonRequest: true });
};

api2.post = (url, params, options) => {
  return api2.request("POST", url, params, options);
};

api2.postByJson = (url, params, options) => {
  return api2.request("POST", url, params, { ...options, jsonRequest: true });
};

export default api2;

import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { useState } from "react";
import { Text, View } from "src/widgets/basic";
import { Beian } from "src/widgets/Beian";
import { BlueButton } from "src/widgets/BlueButton";
import { PasswordInput } from "src/widgets/PasswordInput";

import api2 from "../utils/api2";
import { saveToken } from "../utils/token";
import ui from "../utils/ui";

class LocalStore {
  username = null;
  password = null;

  isSubmitting = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *login() {
    try {
      this.isSubmitting = true;

      const result = yield api2.post("/front/login", {
        username: this.username?.trim(),
        password: this.password,
      });
      const { token } = result.data;
      saveToken(token);
      window.location.replace("/");
    } catch (ex) {
      console.warn(ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

export const LoginPage = observer(function LoginPage() {
  const [localStore] = useState(() => new LocalStore());

  const [css] = useStyletron();

  return (
    <View
      className={css({
        minHeight: "100vh",
        backgroundColor: "#F4F8FE",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <img
        alt="logo"
        src={require("src/images/login_logo.png")}
        className={css({ width: "241px", height: "auto" })}
      />

      <View
        className={css(
          expandWithMerge({
            marginTop: "25px",
            width: "462px",
            height: "396px",
            backgroundColor: "white",
            padding: "34px 42px 64px 60px",
            justifyContent: "space-between",
            alignItems: "center",
          }),
        )}
      >
        <Text $style={{ lineHeight: "27px", fontSize: "24px" }}>用户还款系统</Text>

        <Input
          placeholder="请输入用户名"
          value={localStore.username || ""}
          onChange={action((e) => (localStore.username = (e.target.value || "").trim()))}
          startEnhancer={<img alt="username" src={require("src/images/login_username.png")} />}
          overrides={{
            Root: {
              style: expandWithMerge({
                height: "66px",
                borderColor: "#f4f8fe",
                backgroundColor: "#f4f8fe",
              }),
            },
            Input: {
              style: {
                backgroundColor: "#f4f8fe",
                "::placeholder": {
                  color: "rgba(18, 25, 57, .45)",
                },
              },
            },
            StartEnhancer: { style: { backgroundColor: "#f4f8fe" } },
          }}
        />

        <PasswordInput
          placeholder="请输入密码"
          value={localStore.password}
          onChange={action((e) => (localStore.password = (e.target.value || "").trim()))}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              localStore.login();
            }
          }}
          startEnhancer={<img alt="password" src={require("src/images/login_password.png")} />}
        />

        <BlueButton
          onClick={localStore.login}
          isLoading={localStore.isSubmitting}
          style={{ width: "100%", height: "48px", fontSize: "18px" }}
        >
          登录
        </BlueButton>
      </View>

      <View
        $style={{
          position: "fixed",
          bottom: "20px",
          left: 0,
          right: 0,
          alignItems: "center",
        }}
      >
        <Beian />
      </View>
    </View>
  );
});

import { styled } from "baseui";

export const View = styled("div", {
  display: "flex",
  boxSizing: "border-box",
  position: "relative",
  flexDirection: "column",
});

export const textOverrides = {
  lineHeight: "16px",
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: '"PingFangSC-Regular"',
  color: "#121939",
};

export const Text = styled("span", textOverrides);

export const boldTitleStyle = {
  font: "500 20px/22px PingFangSC-Medium",
};

import { queryKey, useQueryWrapper } from "src/utils/queryHelpers";
import ui from "src/utils/ui";

export function key_bankcard_list() {
  return queryKey("/front/bankcard/list", {}, true);
}

export function useData_bankcard_list() {
  const { data, error, ...rest } = useQueryWrapper(key_bankcard_list());
  ui.useToast(error);
  return {
    data,
    error,
    ...rest,
    bankCardList: data?.bankCardList,
  };
}

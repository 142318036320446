const logic = {};

logic.WITHDRAW_STATUS = {
  打款申请中: "#FCAF3D",
  "已打款(银行处理中)": "magenta",
  打款已到账: "#2A9C9D",
  打款失败: "#FF5B53",
};

export default logic;

import React from "react";
import { useSnapshot } from "valtio";

/**
 * 封装一个简单的获取 localStore 和 valtio snap 的函数，并同时兼容老代码中的 mobx store 和 fake
 * store，方便渐进式迁移。
 */
export function useLocalStoreContext(context, options) {
  const localStore = React.useContext(context);
  let snap;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    snap = useSnapshot(localStore, {
      // NOTICE 嘛，按我们代码的效率，默认开这个选项影响应该也不大，倒是能减轻不少心智负担。
      sync: true,
      ...options,
    });
  } catch (ex) {
    snap = localStore;
  }
  return [localStore, snap];
}

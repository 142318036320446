import { useStyletron } from "baseui";
import { Button } from "baseui/button";
import { Modal } from "baseui/modal";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from "react";
import { invalidate_customer_overview, useData_customer_overview } from "src/data/customer";
import api2 from "src/utils/api2";
import { saveToken } from "src/utils/token";
import ui from "src/utils/ui";
import { usePrevious } from "src/utils/usePrevious";
import { Text, View } from "src/widgets/basic";
import { BlueButton } from "src/widgets/BlueButton";
import { PasswordInput } from "src/widgets/PasswordInput";

class LocalStore {
  oldPassword = null;
  newPassword = null;
  newPassword2 = null;

  isOldPasswordIncorrect = false;
  hasInputNewPassword2 = false;
  isSubmitting = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *modifyPassword(onClose) {
    try {
      this.isSubmitting = true;

      this.hasInputNewPassword2 = false;
      yield api2.post("/front/customer/modify-passwd", {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      });

      ui.toastInfo("密码修改成功，需要重新登录");
      onClose();
      saveToken(null);
      window.location.replace("/Login");
    } catch (ex) {
      console.warn("PasswordEditorModal.modifyPassword", ex);
      if (ex.message === "密码不正确") {
        this.isOldPasswordIncorrect = true;
        return;
      }
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

export const PasswordEditorModal = observer(function PasswordEditorModal({ isVisible, onClose }) {
  const [localStore, setLocalStore] = useState(() => new LocalStore());

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      setLocalStore(new LocalStore());
      invalidate_customer_overview();
    }
  }, [isVisible, prevIsVisible]);

  const { overview = {} } = useData_customer_overview();

  const [css] = useStyletron();

  return (
    <Modal
      isOpen={isVisible}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: expandWithMerge({
            width: "720px",
            borderRadius: 0,
          }),
        },
      }}
    >
      <View $style={{ height: "100%", padding: "32px 24px 31px 60px" }}>
        <Text $style={{ lineHeight: "27px", fontSize: "24px" }}>修改密码</Text>

        <View
          $style={{ marginTop: "16px", height: "48px", flexDirection: "row", alignItems: "center" }}
        >
          <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>姓名：</Text>
          <Text $style={{ lineHeight: "18px", fontSize: "16px" }}>{overview.name}</Text>
        </View>

        <View
          $style={{ marginTop: "16px", height: "48px", flexDirection: "row", alignItems: "center" }}
        >
          <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>身份证号：</Text>
          <Text $style={{ lineHeight: "18px", fontSize: "16px" }}>{overview.idCardNo}</Text>
        </View>

        <View
          $style={{ marginTop: "16px", height: "48px", flexDirection: "row", alignItems: "center" }}
        >
          <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>邮箱：</Text>
          <Text $style={{ lineHeight: "18px", fontSize: "16px" }}>{overview.mail}</Text>
        </View>

        <View
          $style={{ marginTop: "16px", height: "48px", flexDirection: "row", alignItems: "center" }}
        >
          <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>用户名：</Text>
          <Text $style={{ lineHeight: "18px", fontSize: "16px" }}>{overview.username}</Text>
        </View>

        <View $style={{ flex: 1, marginTop: "16px", justifyContent: "space-between" }}>
          <View $style={{ flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>原密码：</Text>
            <PasswordInput
              placeholder="请输入原密码"
              value={localStore.oldPassword}
              onChange={action((e) => {
                localStore.oldPassword = e.target.value;
                localStore.isOldPasswordIncorrect = false;
              })}
              style={{ width: "360px", height: "48px" }}
            />
            {!!localStore.isOldPasswordIncorrect && (
              <View $style={{ marginLeft: "12px", flexDirection: "row", alignItems: "center" }}>
                <img
                  alt="password incorrect"
                  src={require("src/images/password_incorrect.png")}
                  className={css({ width: "24px", height: "auto" })}
                />
                <Text $style={{ marginLeft: "12px", color: "#FE3333" }}>密码不正确</Text>
              </View>
            )}
          </View>

          <View $style={{ marginTop: "16px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>新密码：</Text>
            <PasswordInput
              placeholder="请输入新密码"
              value={localStore.newPassword}
              onChange={action((e) => (localStore.newPassword = e.target.value))}
              style={{ width: "360px", height: "48px" }}
            />
          </View>

          <View $style={{ marginTop: "16px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "106px", lineHeight: "18px", fontSize: "16px" }}>
              确认新密码：
            </Text>
            <PasswordInput
              placeholder="请再输入一遍新密码"
              value={localStore.newPassword2}
              onChange={action((e) => {
                localStore.newPassword2 = e.target.value;
                if (localStore.newPassword2) {
                  localStore.hasInputNewPassword2 = true;
                }
              })}
              onBlur={action(() => (localStore.hasInputNewPassword2 = true))}
              style={{ width: "360px", height: "48px" }}
            />
            {!!localStore.hasInputNewPassword2 && (
              <View $style={{ marginLeft: "12px", flexDirection: "row", alignItems: "center" }}>
                {localStore.newPassword === localStore.newPassword2 ? (
                  <img
                    alt="password correct"
                    src={require("src/images/password_correct.png")}
                    className={css({ width: "24px", height: "auto" })}
                  />
                ) : (
                  <Fragment>
                    <img
                      alt="password incorrect"
                      src={require("src/images/password_incorrect.png")}
                      className={css({ width: "24px", height: "auto" })}
                    />
                    <Text $style={{ marginLeft: "12px", color: "#FE3333" }}>与新密码不一致</Text>
                  </Fragment>
                )}
              </View>
            )}
          </View>
        </View>

        <View
          $style={{
            marginTop: "40px",
            marginLeft: "106px",
            width: "360px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={onClose}
            isLoading={localStore.isSubmitting}
            overrides={{
              BaseButton: {
                style: ({ $disabled }) =>
                  expandWithMerge({
                    boxSizing: "border-box",
                    width: "88px",
                    height: "49px",
                    border: `1px solid ${$disabled ? "lightgray" : "#4A78F6"}`,
                    backgroundColor: "white",
                    fontSize: "18px",
                    color: "#4A78F6",
                    ":hover": { backgroundColor: "#f8f8f8" },
                  }),
              },
            }}
          >
            取消
          </Button>

          <BlueButton
            onClick={() => localStore.modifyPassword(onClose)}
            disabled={
              !localStore.oldPassword ||
              !localStore.newPassword ||
              !localStore.newPassword2 ||
              localStore.newPassword !== localStore.newPassword2
            }
            isLoading={!!localStore.isSubmitting}
            style={{ width: "226px", fontSize: "18px" }}
          >
            确认
          </BlueButton>
        </View>
      </View>
    </Modal>
  );
});

import { useQuery } from "react-query";

import api2 from "./api2";
import { getToken } from "./token";

export function queryKey(url, args, isLoginRequired = true) {
  const token = getToken();
  return [
    url,
    {
      ...(isLoginRequired ? { token } : {}),
      ...args,
    },
    isLoginRequired,
  ];
}

/**
 * 对 useQuery 的一个统一的封装
 *
 * 两种调用形式：
 * - useQueryWrapper(key, api2Options, queryOptions)
 * - useQueryWrapper(url, args, isLoginRequired, api2Options, queryOptions)
 */
export function useQueryWrapper(url, ...restArgs) {
  const token = getToken();

  let key, args, isLoginRequired, api2Options, queryOptions;
  if (typeof url === "string") {
    [args, isLoginRequired, api2Options, queryOptions] = restArgs;
    key = queryKey(url, args, isLoginRequired);
  } else {
    key = url;
    [url, args, isLoginRequired] = key;
    [api2Options, queryOptions] = restArgs;
  }

  return useQuery(
    key,
    async ({ queryKey: [u, a] }) => {
      const result = await api2.get(
        u,
        {
          // HACK 方便过滤日志输出
          xxx_useQuery: 42,
          ...a,
        },
        api2Options,
      );
      return result.data;
    },
    {
      ...queryOptions,
      enabled: !!(
        (queryOptions?.enabled === undefined ? true : queryOptions?.enabled) &&
        (!isLoginRequired || token)
      ),
    },
  );
}

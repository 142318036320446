import { Modal } from "baseui/modal";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { cloneDeep } from "lodash";
import { action, makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import api2 from "src/utils/api2";
import ui from "src/utils/ui";
import { usePrevious } from "src/utils/usePrevious";
import { boldTitleStyle, Text, View } from "src/widgets/basic";
import { BlueButton } from "src/widgets/BlueButton";
import { CancelButton } from "src/widgets/CancelButton";
import { WhiteInput } from "src/widgets/WhiteInput";

class LocalStore {
  onClose = null;

  bankCard = {};

  isSubmitting = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  *addBandCard() {
    try {
      this.isSubmitting = true;

      yield api2.postByJson("/front/bankcard/add", this.bankCard);
      ui.toastInfo("添加银行账户成功");
      this.onClose();
    } catch (ex) {
      console.warn("BankCardEditorModal.addBandCard", ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }

  *modifyBandCard() {
    try {
      this.isSubmitting = true;

      yield api2.postByJson("/front/bankcard/modify", {
        ...this.bankCard,
        bankCardId: this.bankCard.id,
      });
      ui.toastInfo("编辑银行账户成功");
      this.onClose();
    } catch (ex) {
      console.warn("BankCardEditorModal.modifyBandCard", ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

export const BankCardEditorModal = observer(function BankCardEditorModal({
  isVisible,
  onClose,
  modalAction,
  bankCard,
}) {
  const [localStore] = useState(() => new LocalStore());

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      runInAction(() => {
        localStore.onClose = onClose;
        localStore.bankCard = cloneDeep(bankCard || {});
        if (!localStore.bankCard.type) localStore.bankCard.type = "个人";
        if (!localStore.bankCard.bankOfDeposit) localStore.bankCard.bankOfDeposit = "招商银行";
      });
    }
  }, [bankCard, isVisible, localStore, onClose, prevIsVisible]);

  return (
    <Modal
      isOpen={isVisible}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: expandWithMerge({
            borderRadius: 0,
          }),
        },
      }}
    >
      <View $style={{ width: "480px", padding: "20px 30px 30px 18px" }}>
        <Text $style={boldTitleStyle}>{{ ADD: "添加", MODIFY: "编辑" }[modalAction]}银行账户</Text>

        <View $style={{ flex: 1, marginTop: "34px" }}>
          <View $style={{ flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              {localStore.bankCard.type === "公司" ? "公司名称" : "个人姓名"}
            </Text>
            <View $style={{ flex: 1 }}>
              <WhiteInput
                placeholder="请输入持卡人真实姓名"
                value={localStore.bankCard.name || ""}
                onChange={action((e) => (localStore.bankCard.name = e.target.value))}
              />
            </View>
          </View>

          <View
            $style={{
              marginTop: "24px",
              height: "37px",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              开户银行
            </Text>
            <View $style={{ flex: 1, paddingLeft: "14px" }}>
              <Text>{localStore.bankCard.bankOfDeposit || ""}</Text>
            </View>
          </View>

          <View $style={{ marginTop: "24px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              银行账号
            </Text>
            <View $style={{ flex: 1 }}>
              <WhiteInput
                type="number"
                placeholder="请输入银行账号"
                value={localStore.bankCard.account || ""}
                onChange={action((e) => (localStore.bankCard.account = e.target.value))}
              />
            </View>
          </View>
          <Text $style={{ marginTop: "16px", textAlign: "right", color: "#FF0C00" }}>
            * 仅支持添加招商银行的银行卡
          </Text>

          <View $style={{ marginTop: "24px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              开户行说明
            </Text>
            <View $style={{ flex: 1 }}>
              <WhiteInput
                placeholder="请输入开户银行、支行相关信息"
                value={localStore.bankCard.bankOfDepositRemark || ""}
                onChange={action((e) => (localStore.bankCard.bankOfDepositRemark = e.target.value))}
              />
            </View>
          </View>
          <Text $style={{ marginTop: "16px", textAlign: "right", color: "orange" }}>
            * 非必填（未填写有几率导致还款提现申请失败）
          </Text>
        </View>

        <View
          $style={{
            marginTop: "32px",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CancelButton onClick={onClose} isLoading={localStore.isSubmitting}>
            <Text>取消</Text>
          </CancelButton>

          <BlueButton
            onClick={() => {
              switch (modalAction) {
                case "ADD":
                  localStore.addBandCard();
                  break;
                case "MODIFY":
                  localStore.modifyBandCard();
                  break;
                default:
                  break;
              }
            }}
            disabled={
              !localStore.bankCard.type ||
              !localStore.bankCard.name ||
              !localStore.bankCard.bankOfDeposit ||
              !localStore.bankCard.account
            }
            isLoading={!!localStore.isSubmitting}
            style={{ marginLeft: "15px", width: "90px", height: "32px" }}
          >
            确认
          </BlueButton>
        </View>
      </View>
    </Modal>
  );
});

import { useStyletron } from "baseui";
import { DatePicker } from "baseui/datepicker";
import zh from "date-fns/locale/zh-CN";

import { View } from "./basic";
import { whiteInputOverrides } from "./WhiteInput";

export function WhiteDatePicker(props) {
  const [css] = useStyletron();

  return (
    <View>
      <View
        $style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          pointerEvents: "none",
          paddingRight: "13px",
          justifyContent: "center",
        }}
      >
        <img
          alt="calendar"
          src={require("src/images/index_datePicker.png")}
          className={css({ width: "18px", height: "auto" })}
        />
      </View>

      <DatePicker
        locale={zh}
        placeholder="开始时间 - 结束时间"
        formatString="yyyy年MM月dd日"
        {...props}
        overrides={{
          Input: {
            props: {
              overrides: {
                ...whiteInputOverrides,
              },
            },
          },
          Day: {
            style: ({ $selected, $isHighlighted, $isHovered }) => ({
              backgroundColor: "white",
              "::before": {
                backgroundColor: "#F4F8FE !important",
              },
              "::after": {
                borderColor: $selected || $isHighlighted || $isHovered ? "#538CFF" : "transparent",
                backgroundColor: $selected
                  ? "#538CFF"
                  : $isHovered
                  ? "#F4F8FE !important"
                  : "white",
              },
            }),
          },
        }}
      />
    </View>
  );
}

import { Button } from "baseui/button";
import { Modal } from "baseui/modal";
import { StatefulPopover } from "baseui/popover";
import { Table } from "baseui/table-semantic";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { key_bankcard_list, useData_bankcard_list } from "src/data/bankcard";
import { invalidate_customer_overview } from "src/data/customer";
import api2 from "src/utils/api2";
import { theQueryClient } from "src/utils/theQueryClient";
import ui from "src/utils/ui";
import { usePrevious } from "src/utils/usePrevious";
import { boldTitleStyle, Text, View } from "src/widgets/basic";
import { BlueButton } from "src/widgets/BlueButton";
import { LinkButton } from "src/widgets/LinkButton";

import { BankCardEditorModal } from "./BankCardEditorModal";

class LocalStore {
  bankCardEditorModal = null;
  isSubmitting = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  invalidateTableData() {
    invalidate_customer_overview();
    theQueryClient.invalidateQueries(key_bankcard_list());
  }

  *deleteBankCard(bankCardId) {
    try {
      this.isSubmitting = true;

      yield api2.post("/front/bankcard/delete", { bankCardId });
      ui.toastInfo("已删除所选银行账户");

      this.invalidateTableData();
    } catch (ex) {
      console.warn("BankCardsTableModal.deleteBankCard", ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

export const BankCardsTableModal = observer(function BankCardsTableModal({ isVisible, onClose }) {
  const [localStore, setLocalStore] = useState(() => new LocalStore());

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      const newLocalStore = new LocalStore();
      setLocalStore(newLocalStore);
      newLocalStore.invalidateTableData();
    }
  }, [isVisible, prevIsVisible]);

  const { bankCardList = [] } = useData_bankcard_list();

  return (
    <Modal
      isOpen={isVisible}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: expandWithMerge({
            width: "992px",
            borderRadius: 0,
          }),
        },
      }}
    >
      <View $style={expandWithMerge({ padding: "20px 16px" })}>
        <Text $style={boldTitleStyle}>管理银行账户</Text>

        <Text $style={{ marginTop: "10px", fontSize: "16px", color: "red" }}>
          注意：仅支持添加招商银行的银行卡，否则您可能无法成功提现
        </Text>

        <Table
          columns={["姓名", "开户银行", "银行账号", "开户行说明", "操作"]}
          data={bankCardList.map((card) => [
            card.name,
            card.bankOfDeposit,
            card.account,
            card.bankOfDepositRemark || "--",
            <View
              $style={{
                height: "56px",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <LinkButton
                onClick={action(() => {
                  localStore.bankCardEditorModal = {
                    isVisible: true,
                    modalAction: "MODIFY",
                    bankCard: card,
                  };
                })}
              >
                编辑
              </LinkButton>

              <StatefulPopover
                content={({ close }) => (
                  <View
                    $style={expandWithMerge({
                      width: "395px",
                      height: "180px",
                      backgroundColor: "white",
                      padding: "20px 26px 20px 36px",
                    })}
                  >
                    <View $style={{ flexDirection: "row", alignItems: "center" }}>
                      <img alt="warning" src={require("src/images/icon_warning.png")} />
                      <Text $style={{ marginLeft: "12px", ...boldTitleStyle }}>删除确认</Text>
                    </View>
                    <Text $style={{ marginTop: "34px" }}>确认要删除该银行账户吗？</Text>
                    <View $style={{ flex: 1 }} />
                    <View
                      $style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onClick={close}
                        isLoading={localStore.isSubmitting}
                        overrides={{
                          BaseButton: {
                            style: {
                              width: "90px",
                              height: "32px",
                              backgroundColor: "#F4F6FC",
                              ":hover": {
                                backgroundColor: "#F4F6FC88",
                              },
                            },
                          },
                        }}
                      >
                        <Text>取消</Text>
                      </Button>
                      <Button
                        onClick={() => localStore.deleteBankCard(card.id)}
                        isLoading={localStore.isSubmitting}
                        overrides={{
                          BaseButton: {
                            style: {
                              marginLeft: "16px",
                              width: "90px",
                              height: "32px",
                              backgroundColor: "#FD4C4C",
                              ":hover": { backgroundColor: "#FD4C4CCC" },
                            },
                          },
                        }}
                      >
                        <Text $style={{ color: "white" }}>确认</Text>
                      </Button>
                    </View>
                  </View>
                )}
                autoFocus={false}
                placement="left"
                showArrow
                overrides={{
                  Arrow: {
                    style: {
                      right: "-5px",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "white",
                    },
                  },
                }}
              >
                <LinkButton>删除</LinkButton>
              </StatefulPopover>
            </View>,
          ])}
          overrides={{
            Root: {
              style: {
                marginTop: "24px",
              },
            },
            TableHeadCell: {
              style: {
                height: "32px",
                border: "none",
                backgroundColor: "#F4F6FC",
                padding: "0px",
                lineHeight: "32px",
                textAlign: "center",
                fontSize: "14px",
              },
            },
            TableBodyCell: {
              style: ({ $col }) => ({
                ...($col === "操作" ? { width: "160px" } : {}),
                border: "none",
                padding: "0px",
                textAlign: "center",
                lineHeight: "56px",
              }),
            },
          }}
        />

        <BlueButton
          onClick={action(() => {
            localStore.bankCardEditorModal = {
              isVisible: true,
              modalAction: "ADD",
              bankCard: {},
            };
          })}
          style={{ marginTop: "24px", width: "180px", height: "32px" }}
        >
          + 添加银行账户
        </BlueButton>
      </View>

      <BankCardEditorModal
        {...localStore.bankCardEditorModal}
        onClose={action(() => {
          localStore.bankCardEditorModal = null;
          localStore.invalidateTableData();
        })}
      />
    </Modal>
  );
});

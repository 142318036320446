import { Modal } from "baseui/modal";
import { Table } from "baseui/table-semantic";
import { expandWithMerge } from "inline-style-expand-shorthand";
import { action, makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import { key_bankcard_list, useData_bankcard_list } from "src/data/bankcard";
import { invalidate_customer_overview } from "src/data/customer";
import { useData_params_minWithdraw } from "src/data/params";
import { useData_withdraw_applyMaxFee } from "src/data/withdraw";
import api2 from "src/utils/api2";
import format from "src/utils/format";
import { theQueryClient } from "src/utils/theQueryClient";
import ui from "src/utils/ui";
import { usePrevious } from "src/utils/usePrevious";
import { useLocalStoreContext } from "src/utils/utils";
import { boldTitleStyle, Text, View } from "src/widgets/basic";
import { BlueButton } from "src/widgets/BlueButton";
import { CancelButton } from "src/widgets/CancelButton";
import { WhiteInput } from "src/widgets/WhiteInput";

import { BankCardEditorModal } from "./BankCardEditorModal";

const LocalStoreContext = React.createContext({});

class LocalStore {
  navprops = {};

  fee = null;
  bankCardId = null;

  hintModal = null;
  bankCardEditorModal = null;

  isModalOpen = false;
  isSubmitting = false;

  constructor(props) {
    this.navprops = props || {};
    makeAutoObservable(this, {}, { autoBind: true });
  }

  modalOnClose() {
    this.navprops.onClose?.();
    invalidate_customer_overview();
  }

  *apply({ maxApplicableFee, bankCardList, minWithdraw }) {
    try {
      this.isSubmitting = true;

      if (minWithdraw > 0 && !(this.fee >= minWithdraw)) {
        ui.toastError(`单次申请提现的金额不得小于${minWithdraw}元`);
        return;
      }
      if (this.fee > maxApplicableFee) {
        ui.toastError(`申请提现的金额不得大于最大可申请金额${maxApplicableFee}元`);
        return;
      }

      const card = bankCardList.find((item) => item.id === this.bankCardId);
      yield api2.postByJson("/front/withdraw/apply", {
        fee: this.fee,
        bankCardId: this.bankCardId,
        type: card.type,
        name: card.name,
        account: card.account,
        bankOfDeposit: card.bankOfDeposit,
        bankOfDepositRemark: card.bankOfDepositRemark,
      });
      ui.toastInfo("申请提现成功，请及时关注申请状态");
      this.modalOnClose();
    } catch (ex) {
      console.warn("WithdrawApplyModal.apply", ex);
      ui.toastError(ex);
    } finally {
      this.isSubmitting = false;
    }
  }
}

function useApply() {
  const [localStore] = useLocalStoreContext(LocalStoreContext);

  const { maxApplicableFee } = useData_withdraw_applyMaxFee();
  const { bankCardList = [] } = useData_bankcard_list();
  const { minWithdraw } = useData_params_minWithdraw();

  return useCallback(() => {
    localStore.apply({ maxApplicableFee, bankCardList, minWithdraw });
  }, [localStore, maxApplicableFee, bankCardList, minWithdraw]);
}

export const WithdrawApplyModal = observer(function WithdrawApplyModal(props) {
  const { isVisible } = props;
  const [localStore, setLocalStore] = useState(() => new LocalStore(props));

  const prevIsVisible = usePrevious(isVisible);
  useEffect(() => {
    if (!prevIsVisible && isVisible) {
      setLocalStore(new LocalStore(props));
    }
  }, [isVisible, prevIsVisible, props]);

  const { maxApplicableFee } = useData_withdraw_applyMaxFee();
  const { bankCardList = [] } = useData_bankcard_list();

  return (
    <LocalStoreContext.Provider value={localStore}>
      <Modal
        isOpen={isVisible}
        onClose={localStore.modalOnClose}
        autoFocus={false}
        overrides={{
          Dialog: {
            style: expandWithMerge({
              width: "640px",
              height: "280px",
              borderRadius: 0,
            }),
          },
        }}
      >
        <View
          $style={expandWithMerge({
            width: "640px",
            height: "280px",
            padding: "20px 24px 32px 20px",
          })}
        >
          <Text $style={boldTitleStyle}>申请提现</Text>

          <View $style={{ marginTop: "40px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              申请金额
            </Text>
            <View $style={{ width: "200px" }}>
              <FeeInput />
            </View>
            <Text $style={{ marginLeft: "16px", color: "#FD4C4C" }}>
              * 最大可申请金额：¥
              <span
                onClick={action(() => {
                  localStore.fee = parseFloat(format.fee(maxApplicableFee)?.replace(/,/g, ""));
                })}
              >
                {format.fee(maxApplicableFee)}
              </span>
            </Text>
          </View>

          <View $style={{ marginTop: "24px", flexDirection: "row", alignItems: "center" }}>
            <Text $style={{ width: "110px", paddingRight: "12px", textAlign: "right" }}>
              打款账户
            </Text>
            <BlueButton
              onClick={action(() => {
                if (bankCardList.length > 0) {
                  localStore.isModalOpen = true;
                } else {
                  localStore.modalOnClose();
                  localStore.hintModal = { isVisible: true };
                }
              })}
              style={{
                flex: 1,
                height: "37px",
                border: "1px solid rgba(18, 25, 57, .3)",
                backgroundColor: "white",
                justifyContent: "flex-start",
                ":hover": { backgroundColor: "white" },
              }}
            >
              <Text $style={{ opacity: localStore.bankCardId ? 1 : 0.45 }}>
                {(() => {
                  if (localStore.bankCardId) {
                    const card = bankCardList.find((card) => card.id === localStore.bankCardId);
                    return card ? `【${card.bankOfDeposit}】${card.account}` : "";
                  } else {
                    return "请选择...";
                  }
                })()}
              </Text>
            </BlueButton>
          </View>

          <View $style={{ flex: 1 }} />

          <Buttons />
        </View>

        <Modal
          isOpen={localStore.isModalOpen}
          onClose={action(() => (localStore.isModalOpen = false))}
          overrides={{
            Dialog: {
              style: expandWithMerge({
                width: "992px",
                borderRadius: 0,
              }),
            },
          }}
        >
          <View $style={{ padding: "20px 24px 40px 24px" }}>
            <Text $style={boldTitleStyle}>选择打款的银行账户</Text>

            <Table
              columns={["名称", "开启银行", "银行账号", "开户行说明", "操作"]}
              data={bankCardList.map((card) => {
                const isSelected = card.id === localStore.bankCardId;
                return [
                  card.name,
                  card.bankOfDeposit,
                  card.account,
                  card.bankOfDepositRemark || "--",
                  isSelected ? (
                    <CancelButton disabled style={{ width: "64px", height: "24px" }}>
                      <Text>已选</Text>
                    </CancelButton>
                  ) : (
                    <BlueButton
                      onClick={action(() => {
                        localStore.bankCardId = card.id;
                        localStore.isModalOpen = false;
                      })}
                      style={{ width: "64px", height: "24px", fontSize: "14px" }}
                    >
                      选择
                    </BlueButton>
                  ),
                ];
              })}
              overrides={{
                Root: {
                  style: {
                    marginTop: "24px",
                  },
                },
                TableHeadCell: {
                  style: expandWithMerge({
                    height: "32px",
                    border: "none",
                    backgroundColor: "#F4F6FC",
                    padding: "0px",
                    lineHeight: "32px",
                    textAlign: "center",
                    fontSize: "14px",
                  }),
                },
                TableBodyCell: {
                  style: ({ $col }) =>
                    expandWithMerge({
                      ...($col === "操作" ? { width: "140px" } : {}),
                      border: "none",
                      padding: "0px",
                      textAlign: "center",
                      lineHeight: "56px",
                    }),
                },
              }}
            />
          </View>
        </Modal>
      </Modal>

      <Modal
        isOpen={localStore.hintModal?.isVisible}
        onClose={action(() => (localStore.hintModal = null))}
        autoFocus={false}
        overrides={{
          Dialog: {
            style: expandWithMerge({
              borderRadius: 0,
            }),
          },
        }}
      >
        <View
          $style={expandWithMerge({
            padding: "20px 24px 32px 20px",
          })}
        >
          <Text $style={boldTitleStyle}>提示</Text>
          <Text $style={{ marginTop: "16px" }}>您还未添加银行账户，请添加后再操作提现申请</Text>

          <View $style={{ height: "32px" }} />

          <View $style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
            <CancelButton
              onClick={action(() => (localStore.hintModal = null))}
              isLoading={localStore.isSubmitting}
            >
              <Text>取消</Text>
            </CancelButton>

            <BlueButton
              onClick={action(() => {
                localStore.hintModal = null;
                localStore.bankCardEditorModal = {
                  isVisible: true,
                  modalAction: "ADD",
                  bankCard: {},
                };
              })}
              style={{ marginLeft: "15px", width: "120px", height: "32px" }}
            >
              添加银行账户
            </BlueButton>
          </View>
        </View>
      </Modal>

      <BankCardEditorModal
        {...localStore.bankCardEditorModal}
        onClose={action(() => {
          localStore.bankCardEditorModal = null;
          invalidate_customer_overview();
          theQueryClient.invalidateQueries(key_bankcard_list());
        })}
      />
    </LocalStoreContext.Provider>
  );
});

function _FeeInput() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);

  const { maxApplicableFee } = useData_withdraw_applyMaxFee();
  const { minWithdraw } = useData_params_minWithdraw();

  return (
    <WhiteInput
      type="number"
      placeholder="请输入金额数字"
      value={snap.fee || ""}
      onChange={action((e) => {
        localStore.fee = e.target.value;
      })}
      min={minWithdraw}
      max={maxApplicableFee}
      endEnhancer={() => <Text>元</Text>}
    />
  );
}
const FeeInput = observer(_FeeInput);

function _Buttons() {
  const [localStore, snap] = useLocalStoreContext(LocalStoreContext);
  const apply = useApply();

  return (
    <View $style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
      <CancelButton onClick={localStore.modalOnClose} isLoading={snap.isSubmitting}>
        <Text>取消</Text>
      </CancelButton>

      <BlueButton
        onClick={apply}
        disabled={snap.fee == null || !snap.bankCardId}
        isLoading={!!snap.isSubmitting}
        style={{ marginLeft: "15px", width: "90px", height: "32px" }}
      >
        确认
      </BlueButton>
    </View>
  );
}
const Buttons = observer(_Buttons);

import { Button } from "baseui/button";
import { forwardRef } from "react";

export const LinkButton = forwardRef(function LinkButton({ style, children, ...props }, ref) {
  return (
    <Button
      {...props}
      overrides={{
        BaseButton: {
          props: { ref },
          style: () => ({
            height: "26px",
            backgroundColor: "transparent",
            fontSize: "14px",
            color: "#538CFF",
            ":hover": {
              backgroundColor: "transparent",
              color: "#538CFFcc",
            },
            ...style,
          }),
        },
      }}
    >
      {children}
    </Button>
  );
});

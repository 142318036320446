const LOCALSTORAGE_KEY_TOKEN = "token";

export function saveToken(token) {
  if (token) {
    localStorage.setItem(LOCALSTORAGE_KEY_TOKEN, token);
  } else {
    localStorage.removeItem(LOCALSTORAGE_KEY_TOKEN);
  }
}

export function getToken() {
  return localStorage.getItem(LOCALSTORAGE_KEY_TOKEN);
}

import { Button } from "baseui/button";
import { expandWithMerge } from "inline-style-expand-shorthand";

import { textOverrides } from "./basic";

export function PopoverButton(props) {
  return (
    <Button
      {...props}
      overrides={{
        BaseButton: {
          style: expandWithMerge({
            width: "168px",
            height: "56px",
            backgroundColor: "white",
            paddingLeft: "28px",
            justifyContent: "flex-start",
            ...textOverrides,
            ":hover": {
              backgroundColor: "#538CFF",
              color: "white",
            },
          }),
        },
      }}
    />
  );
}
